import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';

const BlogHero = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const DateContainer = styled.div`
  padding: 5px;
  border-radius: 5px;
  background: rgba(155, 255, 255, 0.2);
  font-size: 14px;
`;

const ArticleItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-radius: 5px;
  transition: all 0.2s;
  position: relative;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: yellow;
      width: 4px;
      border-radius: 5px 0 0 5px;
    }
  }
  &:hover ${DateContainer} {
    background: rgb(112, 83, 154);
  }
`;

const BlogIndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="blog"
        keywords={[`devblog`, `javascript`, `react`, `react-native`, `expo`]}
      />
      <BlogHero>
        <h1>blog</h1>
      </BlogHero>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          key={node.id}
          to={`.${node.fields.slug}`}
        >
          <ArticleItem>
            <div>
              <h3>{node.frontmatter.title}</h3>
              <p>{node.frontmatter.teaser || node.excerpt}</p>
            </div>
            <div>
              <DateContainer>{node.frontmatter.date}</DateContainer>
            </div>
          </ArticleItem>
        </Link>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { status: { eq: "published" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MM/DD/YY")
            teaser
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
export default BlogIndexPage;
